
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';
import { keyBy } from 'lodash';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';

export default defineComponent({
  name: 'product-list',
  components: {
    Datatable,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      start_date: '',
      end_date: '',
      customer_id: '',
      product_category_id:'',
      so_number:'',
      lists: [],
      search: '',
      tableData: [],
      invoices: [] as any,
      invoiceDetails: [] as any,
      grand_total: 0 as any,
      vat_total: 0 as any,
      data: {},
      showSearchInfo: {
        customer_name: '' as any,
        category_name: '' as any,
        so_number: '' as any,
        invoice_number: '' as any,
        start_date: '' as any,
        end_date: '' as any
      } as any,
      moment: '' as any,
      customerList: [] as any,
      salesmanList: [] as any,
      categoryList: [] as any,
      invoice_number: '' as any,
      customer_name: '' as any,
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      // await this.getInvoiceData();
      await this.getCustomerList();
      await this.getSalesmanList();
      await this.getCategoryData();
      this.moment= moment;
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    async getCategoryData() {
      await ApiService.get('finmanInvoice/category/list?type=1')
        .then((response) => {
          this.categoryList = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getCustomerList() {
      await ApiService.get('finmanInvoice/customer_list')
        .then((response) => {
          this.customerList = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getSalesmanList() {
      await ApiService.get('finmanInvoice/salesman/list')
        .then((response) => {
          this.salesmanList = response.data.data;
          this.componentKey += 1;
          console.log(this.salesmanList);
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    // async getInvoiceData() {
    //   await ApiService.get('finmanInvoice/invoice/getSalesReport')
    //     .then((response) => {
    //       this.invoices = response.data.data.invoice;
    //       this.invoiceDetails= response.data.data.invoice_details;
    //       this.componentKey += 1;
    //       this.load = true;
    //       const sum = this.invoiceDetails.reduce(
    //         (acc, cur) => {
    //           acc.grand_total += cur.net_total;
    //           return acc;
    //         },
    //         {
    //           grand_total: 0,
    //         }
    //       );
    //   this.grand_total= sum.grand_total;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //       this.load = false;
    //     });
    // },

    async getInvoiceDataWithDate() {
      this.load= true;
      let start_date= this.start_date;
      let end_date= this.end_date;
      let customer_id= this.customer_id;
      let so_number= this.so_number;
      let category_id= this.product_category_id;
      let invoice_number= this.invoice_number;
      
      this.showSearchInfo.start_date= start_date;
      this.showSearchInfo.end_date= end_date;
      this.showSearchInfo.so_number= so_number;
      this.showSearchInfo.invoice_number= invoice_number;

      if(this.customer_id)
      {
        let customer= this.customerList.find(element => element.id == this.customer_id);
        let customer_name= customer.name;
        this.showSearchInfo.customer_name= customer_name;
      }

      if(category_id)
      {
        let category= this.categoryList.find(element => element.id == this.product_category_id);
        console.log(category);
        let category_name= category.category_name;
        this.showSearchInfo.category_name= category_name;
      }
      
      await ApiService.get('finmanInvoice/invoice/getSalesReport?startdate='+ `${start_date}` + '&enddate='+ `${end_date}` + '&customer='+ `${customer_id}` + '&category='+ `${category_id}` + '&so='+ `${so_number}` + '&invoice_number='+ `${invoice_number}` )
      .then((response) => {
        this.invoiceDetails= response.data.data.invoice_details;
        this.invoices = response.data.data.invoice;
        if(this.invoiceDetails.length<=0 || this.invoices.length<=0)
        {
          this.invoices.length=0;
          this.invoiceDetails.length= 0;
        }
        else
        {
          this.invoices = response.data.data.invoice;
          this.invoiceDetails= response.data.data.invoice_details;
        }
        this.componentKey += 1;
        this.load = false;
      })
      .catch(({ response }) => {
        console.log(response);
        this.load = false;
      });
    },

    calculateAmountTotal()
    {
        var invoices= this.invoices;
        var invoiceDetails= this.invoiceDetails;
        let amount = 0;
        invoices.forEach(inv => {
          invoiceDetails.forEach(inv_d => {
            if (inv_d.invoice_id == inv.id) {
              amount += (Number(inv_d.unit_price) * Number(inv_d.quantity));
            }
          });
        });
        return amount;
    },

    calculateQuantityTotal()
    {
        var invoices= this.invoices;
        var invoiceDetails= this.invoiceDetails;
        let qty= 0;
        invoices.forEach(inv => {
          invoiceDetails.forEach(inv_d => {
            if (inv_d.invoice_id == inv.id) {
              qty += Number(inv_d.quantity);
            }
          });
        });
        return qty;
    },

    calculateVatTotal()
    {
        // this.vat_total+= Number(vat_amount);
        // return vat_amount;
        var invoices= this.invoices;
        var invoiceDetails= this.invoiceDetails;
        let vat_total = 0;
        invoices.forEach(inv => {
          invoiceDetails.forEach(inv_d => {
            if (inv_d.invoice_id == inv.id) {
              vat_total += inv_d.vat_amount;
            }
          });
        });
        return vat_total;
    },
    calculateGrandTotal()
    {
        // this.vat_total+= Number(vat_amount);
        // return vat_amount;
        var invoices= this.invoices;
        var invoiceDetails= this.invoiceDetails;
        let grand_total = 0;
        invoices.forEach(inv => {
          invoiceDetails.forEach(inv_d => {
            if (inv_d.invoice_id == inv.id) {
              grand_total += inv_d.net_total;
            }
          });
        });
        return grand_total;
    },

    printReport(){
      const content = document.getElementById('report-content');
      const printWindow = window.open('', '_blank', 'height=720,width=1200');
      if (!printWindow) {
        return;
      }
      if (!content) {
        return;
      }
      printWindow.document.write('<html><head><title>Sales Report</title><style>table, thead, th, td{border: 1px solid #000;}</style>');
      printWindow.document.write('<style>@page{margin:0}@media print{body{font-size:9pt; line-height: 1.1} #report-content{ line-height: 1.1, display:block;width:100%;height:auto;}table, thead, th, td{border: 1px solid #000;}}</style>');
      printWindow.document.write('</head><body>');
      printWindow.document.write(content.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    },
    excelReport(){
      // var table = document.getElementById("reportTable");
      // // Convert table to sheet
      // var wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

      // // Generate file name
      // var fileName = "table-export.xlsx";

      // // Convert workbook to binary array
      // var binaryArray = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // // Create blob and download link
      // var blob = new Blob([binaryArray], { type: "application/octet-stream" });
      // var downloadLink = document.createElement("a");
      // downloadLink.href = window.URL.createObjectURL(blob);
      // downloadLink.download = fileName;

      // // Trigger download
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
    },
    downloadPDF() {
      // const doc = new jsPDF();
      // doc.autoTable({ html: '#report-table' });
      // doc.save('report.pdf');
      const content = document.getElementById('report-content');
      html2pdf().from(content).save('report.pdf');
    },
    downloadExcel() {
      const worksheet = XLSX.utils.table_to_sheet(document.getElementById('report-table'));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
      XLSX.writeFile(workbook, 'Sales Report.xlsx');
    }
  },
});
